<template>
   <div class="playlist navPush" v-if="pageIsReady">
      <v-style>
         body {
         {{ background }}
         }
         #nav.fixed ul {background-color: {{ hexCodes.bg1 }};border-color: {{ hexCodes.bg1 }};}
         p a, .link, .links a, #nav a, #nav .fa-icon {color: {{ hexCodes.link }} !important}
         p a:hover, .link:hover, .links a:hover {border-color: {{ hexCodes.link }} !important}
         p, li {color: {{ hexCodes.text }} !important}
         hr {border-color: {{ hexCodes.text }} !important}
         h1 {color: {{ hexCodes.header }} !important}

         .btn > *,
         #logo span {color: {{ hexCodes.header }} !important; border-color: {{ hexCodes.header }}
         !important}
         .btn::before,
         .btn::after,
         #logo::before,
         #logo::after {background: {{ hexCodes.header }} !important}

         .label span {color: {{ hexCodes.header }} !important}
         .tag a {background-color: {{ hexCodes.tagBg }} !important; color: {{ hexCodes.tagText }}
         !important}
         .tag a:hover {border-color: {{ hexCodes.tagBg }} !important; color: {{ hexCodes.tagBg }}
         !important}
      </v-style>

      <h1>{{ entry.title }}</h1>
      <div class="row">
         <div class="col-xs-12 col-md-6">
            <div v-if="entry.description" v-html="entry.description"></div>
            <hr v-if="entry.description" class="my-2">
            <p v-if="entry.think"><span class="font-weight-bold">Think of:</span> {{ entry.think }}
            </p>
         </div>
      </div>

      <TagsGroup v-if="entry.moods.length" header="Feels" :tags="entry.moods" group="moods" />

      <ol>
         <li v-for="(track, key) in entry.tracklist" :key="key">
            "{{ track.title }}" - {{ track.artist }}
         </li>
      </ol>

      <p>{{ entry.duration }}</p>

      <TagsGroup v-if="entry.genres.length" header="Sounds like" :tags="entry.genres"
                 group="genres" />
      <TagsGroup v-if="entry.colors.length" header="Colors" :tags="entry.colors" group="colors" />

      <div class="mt-5">
         <Button v-if="spotifyUrl" :attributes="{ href: spotifyUrl, target: '_blank'}"
                 text="Listen on Spotify" icon="play" />
      </div>

      <Swatches :swatches="entry.swatches" :size="30" />
   </div>
</template>


<script>
    import axios from 'axios';
    import _ from 'lodash';
    import {getMetaTags} from '@/plugins/functions.js';
    import TagsGroup from '@/components/playlists/TagsGroup.vue';
    import Swatches from '@/components/playlists/Swatches.vue';
    import Button from '@/components/Button.vue';

    /*
       How this works (2 ways to view this page):
       1. Coming here from a link somewhere on the site:
            1. The fastest way to load the entry is to pass it through the router link's params (if
               the entry is available) and set Data's entry to it. If the router doesn't have that param,
               Data's entry is undefined (see #2).
            2. If the router doesn't have it, it definitely will have the entry's slug. The backup
               plan is to wait for the component to be mounted, and since the AJAX call for playlists
               has already been made and set as a prop to all of router's views (see App.vue), then
               we call the setEntry() method, which will loop through all of the playlists and find
               the one that matches the router's slug.
        2. Landing here as the first page seen on the site:
            1. Since nothing loaded the entry into the router params, and since there are no playlists
               from an initial AJAX call, we have to wait for that AJAX call to happen in App.vue
               (the parent component). The 'playlists' prop is set to an empty object until Vue
               updates it with the result of the AJAX call from the parent App.vue component.
               A watcher method watches 'playlists' for the update  and triggers the setEntry() method
               when 'playlists' length goes from 0 to however many playlists there are.

        Nothing in the template above renders until the pageIsReady computed property is true, which
        safeguards from trying to access properties on an undefined or empty Data entry.
     */

    export default {
        name: 'Playlist',
        data() {
            return {
                playlists: this.content.playlists,
                entry: this.$route.params.entry,
                meta: this.content.metaData,
                previousRoute: {
                    name: null,
                    path: null,
                },
            }
        },
        components: {
            TagsGroup,
            Button,
            Swatches
        },
        props: {
            content: Object,
            slug: String
        },
        computed: {
            spotifyUrl() {
                if (this.entry.spotifyPlaylistId === null) {
                    return this.entry.spotifyPlaylistId;
                }
                return 'spotify:playlist:' + this.entry.spotifyPlaylistId;
            },
            background() {
                let pattern = this.entry.pattern;
                let hex = this.entry.hexCodes;

                // no pattern, just use background color
                if (pattern === null) {
                    return 'background-color:' + hex.bg1 + ' !important;';
                }

                let value, code1, code2 = '';
                switch (pattern.type) {
                    case 'dots':
                        code1 = `to bottom, transparent 50%, ${hex.bg1} 50%`;
                        code2 = `to right, ${hex.bg2} 50%, ${hex.bg1} 50%`;
                        value = `
                           background-color: ${hex.bg1} !important;
                           background-image: -moz-linear-gradient(${code1}),
                                             -moz-linear-gradient(${code2});
                           background-image: -webkit-linear-gradient(${code1}),
                                             -webkit-linear-gradient(${code2});
                           background-image: linear-gradient(${code1}),
                                             linear-gradient(${code2});
                           background-size: ${pattern.size}px ${pattern.size}px, ${pattern.size}px ${pattern.size}px;
                        `;
                        break;
                    case 'stripes':
                        code1 = `to right, ${hex.bg2} 50%, ${hex.bg1} 50%`;
                        value = `
                           background-color: ${hex.bg1} !important;
                           background-image: -moz-linear-gradient(${code1});
                           background-image: -webkit-linear-gradient(${code1});
                           background-image: linear-gradient(${code1});
                           background-size: ${pattern.size}px ${pattern.size}px;
                        `;
                        break;
                    case 'gradient':
                        code1 = `45deg, ${hex.bg1}, ${hex.bg2}`;
                        value = `
                           background: ${hex.bg1} !important;
                           background: -moz-linear-gradient(${code1}) !important;
                           background: -webkit-linear-gradient(${code1}) !important;
                           background: linear-gradient(${code1}) !important;
                        `;
                        break;
                }

                return value;
            },
            pageIsReady() {
                if (_.isUndefined(this.entry) || _.isEmpty(this.entry)) {
                    return false;
                }

                return true;
            },
            hexCodes() {
                if (this.pageIsReady) {
                    return this.entry.hexCodes;
                }
                return {};
            },
        },
        methods: {
            getMetaTags,
            setEntry() {
                if (this.playlists.length && _.isUndefined(this.entry)) {
                    let _this = this;
                    this.playlists.forEach(function(entry) {
                        if (entry.slug === _this.slug) {
                            _this.entry = entry;
                        }
                    });
                    if (_.isUndefined(this.entry)) {
                        this.$router.replace({name: 'pageNotFound'});
                    }
                }
            }
        },
        watch: {
            playlists() {
                this.setEntry();
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.previousRoute.name = from.name;
                vm.previousRoute.path = from.path;
                next();
            })
        },
        beforeRouteLeave(to, from, next) {
            to.params.scrollToSlug = this.slug;
            next();
        },
        mounted() {
            this.setEntry();

            // scrolling up or down via the keyboard. 110 is the number that brings a Mac desktop
            // viewport nicely above the title of the playlist.
            this.$mousetrap.bind('down', (event) => {
                event.preventDefault();
                window.scrollTo({
                    left: 0,
                    top: parseInt(document.documentElement.scrollTop) + 110,
                    behavior: 'auto'
                });
            });
            this.$mousetrap.bind('up', (event) => {
                event.preventDefault();
                window.scrollTo({
                    left: 0,
                    top: parseInt(document.documentElement.scrollTop) - 110,
                    behavior: 'auto'
                });
            });

            // go back to listing page via keyboard
            this.$mousetrap.bind('left', (event) => {
                event.preventDefault();

                // routes that have playlist listings on them
                let listingRoutes = [
                    'homepage',
                    'genres',
                    'moods',
                    'colors',
                    'activities',
                    'times',
                ];

                // if you came to this playlist any other way (like you landed here from a link on
                // facebook), then just go back to the homepage
                let routePath = '/';

                // if you came to this playlist from a listing page, hitting 'left' on the keyboard
                // will tak you back to that page.
                if (_.includes(listingRoutes, this.previousRoute.name)) {
                    routePath = this.previousRoute.path;
                }

                this.$router.push({
                    path: routePath
                });
            });
        },

        // remove these key bindings when leaving the page so they don't interfere elsewhere
        beforeDestroy() {
            this.$mousetrap.unbind('down');
            this.$mousetrap.unbind('up');
            this.$mousetrap.unbind('left');
        },

        // change the meta info dynamically per playlist
        metaInfo() {
            if (this.pageIsReady) {
                return this.getMetaTags(
                    this.entry.meta.title,
                    this.entry.meta.url,
                    this.entry.meta.image,
                    this.entry.meta.description
                );
            }

            return {};
        },
    }
</script>

<style scoped lang="scss">
   .playlist {
      padding-bottom: 5rem;
      ol {
         padding-left: 29px;
      }
      > > > h1 {
         margin-top: 0;
         letter-spacing: 2px;
         text-transform: none;
      }

      @include media-breakpoint-down(lg) {
         ol {
            padding-left: 22px;
            font-size: 0.8rem;
         }
      }
   }
</style>